import { GroupAnnouncementsService } from 'Roblox';
import groupModule from '../groupModule';

function groupAnnouncements() {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      group: '<',
      communityInfo: '<',
      isOwner: '<',
      announcement: '<',
      showLegacyShouts: '<',
      joinGroup: '<',
      allowedToJoinGroup: '<',
      policies: '<',
      metadata: '<'
    },
    link(scope, element) {
      const renderAnnouncements = () => {
        GroupAnnouncementsService?.renderGroupAnnouncementsSection(element[0], {
          group: scope.group,
          propsCommunityInfo:
            Object.keys(scope.communityInfo).length === 0 ? null : scope.communityInfo,
          isOwner: scope.isOwner,
          announcement: scope.announcement.id ? scope.announcement : null,
          joinGroup: scope.joinGroup,
          allowedToJoinGroup: scope.allowedToJoinGroup,
          policies: scope.policies,
          metadata: scope.metadata
        });
      };

      element.ready(renderAnnouncements);

      ['communityInfo', 'isOwner', 'announcement', 'showLegacyShouts'].forEach(prop => {
        scope.$watch(
          prop,
          (newVal, oldVal) => {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
              renderAnnouncements();
            }
          },
          true
        );
      });
    }
  };
}

groupModule.directive('groupAnnouncements', groupAnnouncements);

export default groupAnnouncements;
