import { CurrentUser, DisplayNames, EventStream, AccountIntegrityChallengeService } from 'Roblox';
import { initRobloxBadgesFrameworkAgnostic } from 'roblox-badges';
import groupModule from '../groupModule';

function groupWallController(
  $scope,
  $interval,
  groupWallService,
  groupDetailsConstants,
  $log,
  groupResources,
  $uibModal,
  systemFeedbackService,
  thumbnailConstants,
  cursorPaginationService,
  $filter,
  $q,
  captchaV2Constants,
  languageResource,
  groupDetailsService,
  groupsConstants,
  httpService,
  groupsService
) {
  'ngInject';

  const ctrl = this;
  const { Generic } = AccountIntegrityChallengeService;

  $scope.groupWall = {
    posts: [],
    deferredNotifications: [],
    postData: '',
    isPostInProgress: false,
    hasNewPosts: false,
    loadFailure: false,
    postMaxLength: groupDetailsConstants.groupPostMaxLength,

    pager: cursorPaginationService.createPager({
      sortOrder: cursorPaginationService.sortOrder.Desc,
      pageSize: groupDetailsConstants.wallPageSize,
      loadPageSize: groupDetailsConstants.cursorPageLoadSize,

      getCacheKeyParameters() {
        return {
          groupId: ctrl.groupId
        };
      },

      getRequestUrl() {
        return $filter('formatString')(groupDetailsConstants.urls.groupWallPostsV2, {
          id: ctrl.groupId
        });
      },

      getDataListFromResponse(response) {
        return $q(function (resolve, reject) {
          resolve(
            response.data
              .filter(function (post) {
                return post.poster;
              })
              .map($scope.convertResultToPostObject)
          );
        });
      },

      loadSuccess(wallPosts) {
        $scope.groupWall.posts = $scope.groupWall.posts.concat(wallPosts);
        $scope.groupWall.hasNewPosts = false;
        try {
          initRobloxBadgesFrameworkAgnostic({
            overrideIconClass: 'verified-badge-icon-group-comments'
          });
        } catch (e) {
          // noop
        }
      },

      loadError(errors) {
        $log.debug('--loadWallPosts-error---');
        $scope.groupWall.loadFailure = true;
      }
    })
  };

  $scope.notificationHandler = function (data, deferred) {
    if (data.Payload.Type == 'NewPost' && data.Payload.GroupId == ctrl.groupId) {
      if ($scope.groupWall.isPostInProgress && !deferred) {
        $scope.groupWall.deferredNotifications.push(data);
        return;
      }
      if (EventStream) {
        EventStream.SendEventWithTarget(
          'wallPostNotificationReceived',
          'groupWall',
          { groupId: ctrl.groupId, postId: data.Payload.PostId },
          EventStream.TargetTypes.WWW
        );
      }
      if (!$scope.groupWall.hasNewPosts) {
        for (let i = 0; i < $scope.groupWall.posts.length; ++i) {
          if ($scope.groupWall.posts[i].id == data.Payload.PostId) {
            return;
          }
        }
        $scope.$evalAsync($scope => ($scope.groupWall.hasNewPosts = true));
        if (EventStream) {
          EventStream.SendEventWithTarget(
            'seeNewPostsEnabled',
            'groupWall',
            { groupId: ctrl.groupId },
            EventStream.TargetTypes.WWW
          );
        }
      }
    }
  };

  $scope.convertResultToPostObject = function (result) {
    if (result.poster && result.poster.user) {
      // v2 api also has a role object
      const poster = {
        userId: result.poster.user.userId,
        username: result.poster.user.username,
        displayName: result.poster.user.displayName,
        buildersClubMembershipType: result.poster.user.buildersClubMembershipType,
        role: result.poster.role,
        hasVerifiedBadge: result.poster.user.hasVerifiedBadge
      };

      return {
        id: result.id,
        poster,
        body: result.body,
        posted: new Date(result.created)
      };
    }

    const poster = {
      userId: result.poster.userId,
      username: result.poster.username,
      displayName: result.poster.displayName,
      buildersClubMembershipType: result.poster.buildersClubMembershipType,
      role: ctrl.role,
      hasVerifiedBadge: result.poster.hasVerifiedBadge
    };

    return {
      id: result.id,
      poster,
      body: result.body,
      posted: new Date(result.created)
    };
  };

  $scope.onShowWallPost = function () {
    // This is called by a React component, so we need to use $apply to update the scope and the view to change
    $scope.$apply(() => {
      $scope.hideWallPost = false;
      if (EventStream) {
        EventStream.SendEventWithTarget(
          'seeWallPostClicked',
          'groupWall',
          { groupId: ctrl.groupId },
          EventStream.TargetTypes.WWW
        );
      }
    });
  };

  $scope.canPostToWall = () => {
    return (
      ctrl.permissions &&
      ctrl.permissions.groupPostsPermissions &&
      ctrl.permissions.groupPostsPermissions.postToWall &&
      !ctrl.readOnly
    );
  };

  $scope.canDeleteWallPost = post => {
    return (
      parseInt(CurrentUser.userId, 10) === post.poster.userId ||
      ($scope.isInGroup() && $scope.canDeleteWallPostOfUserWithRank(post.poster.role.rank))
    );
  };

  $scope.showWallPrivacySettingsText = function () {
    return !$scope.canPostToWall();
  };

  $scope.canSendPost = function () {
    return (
      $scope.groupWall.postData &&
      !$scope.groupWall.isPostInProgress &&
      !$scope.groupWall.captchaActivated
    );
  };

  $scope.sendPost = function (captchaPassed, captchaData) {
    if (!captchaPassed && !$scope.canSendPost()) {
      return;
    }

    $scope.groupWall.isPostInProgress = true;

    groupWallService.sendPost(ctrl.groupId, $scope.groupWall.postData, captchaData).then(
      function (result) {
        $scope.groupWall.posts.unshift($scope.convertResultToPostObject(result));
        $scope.groupWall.postData = '';
        $scope.groupWall.isPostInProgress = false;
        $scope.processDeferredNotifications();
        try {
          initRobloxBadgesFrameworkAgnostic({
            overrideIconClass: 'verified-badge-icon-group-comments'
          });
        } catch (e) {
          // noop
        }
      },
      function (data) {
        // Ignore challenge abandons.
        if (Generic.ChallengeError.matchAbandoned(data)) {
          $scope.groupWall.isPostInProgress = false;
          return;
        }
        if (data && data.errors && data.errors[0]) {
          // Case 1: API error.
          const primaryErrorCode = httpService.getPrimaryApiErrorCode(
            data,
            groupsConstants.errorCodes.sendGroupWallPost
          );
          switch (primaryErrorCode) {
            case groupsConstants.errorCodes.internal.captcha:
              $scope.captchaReturnTokenInSuccessCb = true;
              $scope.groupWall.captchaActivated = true;
              $scope.groupWall.isPostInProgress = false;
              $scope.captchaInputParams = { unifiedCaptchaId: '', dataExchange: '' };
              if (data.errors[0].fieldData) {
                const captchaData = data.errors[0].fieldData;
                let jsonData = '';
                try {
                  jsonData = JSON.parse(captchaData);
                  $scope.captchaInputParams.dataExchange = jsonData.dxBlob;
                  $scope.captchaInputParams.unifiedCaptchaId = jsonData.unifiedCaptchaId;
                } catch (e) {
                  // backward compatible with old version that returns string only.
                  $scope.captchaInputParams.dataExchange = captchaData;
                }
              }
              break;
            default:
              $scope.sendWallPostFailed();
              break;
          }
        } else {
          // CASE 2: Catch-all.
          $scope.sendWallPostFailed();
        }
        $scope.processDeferredNotifications();
      }
    );
  };

  $scope.processDeferredNotifications = function () {
    while ($scope.groupWall.deferredNotifications.length > 0) {
      $scope.notificationHandler($scope.groupWall.deferredNotifications.shift(), true);
    }
  };

  $scope.sendPostCaptchaPassed = function (captchaData) {
    $scope.sendPost(true, captchaData);
  };

  $scope.sendWallPostFailed = function () {
    systemFeedbackService.warning(
      languageResource.get(groupDetailsConstants.translations.sendPostError)
    );
    $scope.groupWall.isPostInProgress = false;
    $log.debug('--sendPost-error---');
  };

  $scope.isInGroup = function () {
    if (ctrl.role) {
      return ctrl.role.id !== null && ctrl.role.rank != 0;
    }

    return false;
  };

  $scope.isInfiniteScrollingDisabled = function () {
    return $scope.groupWall.pager.isBusy() || $scope.groupWall.loadFailure || $scope.hideWallPost;
  };

  $scope.loadInitialWallPost = function () {
    $scope.groupWall.pager.loadFirstPage();
  };

  $scope.reloadWallPosts = function () {
    $scope.groupWall.loadFailure = false;
    $scope.groupWall.posts = [];
    $scope.loadInitialWallPost();
  };

  $scope.groupWall.loadMore = function () {
    if ($scope.groupWall.nextPageCursor) {
      $scope.loadWallPosts($scope.groupWall.nextPageCursor);
    }
  };

  $scope.canSeeNewWallPosts = function () {
    return $scope.groupWall.hasNewPosts;
  };

  $scope.seeNewWallPosts = function () {
    $scope.reloadWallPosts();
    if (EventStream) {
      EventStream.SendEventWithTarget(
        'seeNewPostsClicked',
        'groupWall',
        { groupId: ctrl.groupId },
        EventStream.TargetTypes.WWW
      );
    }
  };

  $scope.deleteWallPost = function (post) {
    groupWallService.deleteWallPost(ctrl.groupId, post.id).then(
      function () {
        systemFeedbackService.success(
          languageResource.get(groupDetailsConstants.translations.deleteWallPostSuccess)
        );
        $scope.groupWall.posts.splice($scope.groupWall.posts.indexOf(post), 1);
      },
      function (data) {
        systemFeedbackService.warning(
          languageResource.get(groupDetailsConstants.translations.deleteWallPostError)
        );
        $log.debug('--deleteWallPost-error---');
      }
    );
  };

  $scope.canDeleteWallPostOfUserWithRank = function (userRank) {
    return (
      ctrl.permissions.groupPostsPermissions.deleteFromWall &&
      $scope.isHigherRankThanUserRank(userRank)
    );
  };

  $scope.canExileUserWithRank = function (userRank) {
    return (
      ctrl.permissions.groupMembershipPermissions.removeMembers &&
      $scope.isHigherRankThanUserRank(userRank)
    );
  };

  $scope.canBanUserWithRank = function (userRank) {
    return (
      ctrl.permissions.groupMembershipPermissions.banMembers &&
      ctrl.policies.displayGroupBans &&
      $scope.isHigherRankThanUserRank(userRank)
    );
  };

  $scope.isHigherRankThanUserRank = function (userRank) {
    return ctrl.role.rank > userRank;
  };

  $scope.showExileUserModal = function (userId, userRank) {
    const modalParams = {
      animation: false,
      templateUrl: groupResources.modals.exileUser.templateUrl,
      controller: groupResources.modals.exileUser.controller,
      resolve: {
        modalData: {
          groupId: ctrl.groupId,
          userId,
          showDeletePostsCheckbox: $scope.canDeleteWallPostOfUserWithRank(userRank),
          refreshGroupWall() {
            $scope.reloadWallPosts();
          },
          reloadCurrentPage: angular.noop
        }
      }
    };

    $uibModal.open(modalParams);
  };

  $scope.showBanUserModal = function (userId, userRank) {
    const modalParams = {
      animation: false,
      templateUrl: groupResources.modals.banUser.templateUrl,
      controller: groupResources.modals.banUser.controller,
      resolve: {
        modalData: {
          groupId: ctrl.groupId,
          userId,
          showDeletePostsCheckbox: $scope.canDeleteWallPostOfUserWithRank(userRank),
          refreshGroupWall() {
            $scope.reloadWallPosts();
          },
          reloadCurrentPage: angular.noop
        }
      }
    };

    $uibModal.open(modalParams);
  };

  $scope.abusePageUrl = function (type, id) {
    return groupDetailsService.abusePageUrl(type, id);
  };

  $scope.profilePageUrl = function (userId) {
    return groupDetailsService.profilePageUrl(userId);
  };

  $scope.loadConfigureGroupPolicies = function () {
    if (ctrl.policies.length > 0) {
      return;
    }

    groupsService.getConfigureGroupRules().then(
      response => {
        ctrl.policies = response;
      },
      () => {
        $log.debug('--loadConfigureGroupPolicies-error---');
      }
    );
  };

  $scope.initHideWallPost = function () {
    $scope.hideWallPost = true;
    if (ctrl.readOnly === false) {
      $scope.hideWallPost = false;
    }
  };

  const init = function () {
    ctrl.isInitialized = true;
    ctrl.policies = {};

    ctrl.isDisplayNamesEnabled = DisplayNames.Enabled();
    ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;

    $scope.loadConfigureGroupPolicies();
    $scope.initHideWallPost();

    $scope.groupDetailsConstants = groupDetailsConstants;
    $scope.captchaActionTypes = captchaV2Constants.captchaActionTypes;
    $scope.loadInitialWallPost();
  };

  const onChanges = changesObj => {
    // If we haven't initialized this yet, return
    if (!ctrl.isInitialized) {
      return;
    }

    if ('readOnly' in changesObj) {
      $scope.initHideWallPost();
    }

    if ('groupId' in changesObj) {
      $scope.reloadWallPosts();
    }
  };

  ctrl.$onInit = init;
  ctrl.$onChanges = onChanges;
}

groupModule.controller('groupWallController', groupWallController);
export default groupWallController;
